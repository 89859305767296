.Club-header {
  position: relative;
  top: 10vh;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  font-family: 'Poiret One', serif;
}
/* 
.Club-header {
  position: relative;
  background-color: #FFFFFF;
  min-height: 100vh;
  left: 5vw;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
  font-size: calc(10px + 2vmin);
  color: white;
  background-image: url('https://volsvik.com/photography/Club.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
} */

/* .Club {
  background-color: #FFFFFF;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
  font-size: calc(10px + 2vmin);
  color: white;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
} */

.Club-Galleria {
  position: relative;
  left: 7vw;
  margin: 7vh;
  /* top: 14vh; */
  width: 80vw;
  min-width: 180px;
}

.Club_Сell {
  position: relative;
  display: flex;
  /* flex-wrap: wrap; */
    flex-direction: column;
      align-items: center;
  justify-content: center;
  /* justify-content: space-around; */
  color: black;
}

.PhotoClub {
  background-color: #282c34;
  width: 90vw;
  height: 40vw;
  margin: 2vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}

.Description {
  width: 90vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: black;
}

/*=========================400=======================*/

@media (min-width: 400px) {

/* .PhotoClub {
  width: 70vw;
  height: 64vh;
} */

}

/*=========================1055=======================*/

@media (min-width: 1055px) {

/* .PhotoClub {
  width: 90vw;
  height: 40vw;
} */

.Description {
  width: 40vw;
  min-width: 500px;
}

}