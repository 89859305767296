.Celebration-header {
  position: relative;
  top: 10vh;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  font-family: 'Poiret One', serif;
}

.Celebration-Galleria {
  position: relative;
  left: 7vw;
  /* top: 14vh; */
    margin: 7vh;
  width: 80vw;
    cursor: pointer;
  min-width: 180px;
}

.Celebration_Сell {
  position: relative;
  display: flex;
  /* flex-wrap: wrap; */
    flex-direction: column;
      align-items: center;
  justify-content: center;
  /* justify-content: space-around; */
  color: black;
}

.PhotoCelebration {
  background-color: white;
  width: 90vw;
  height: 40vw;
  margin: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}

.Header {
    background-image: url('https://volsvik.com/photography/Club/Celebration/Celebration-0001.jpg');
}

.PhotoCelebration01 {
  background-color: white;
  width: 50vh;
  height: 70vh;
  margin: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}

.PhotoCelebration02 {
  background-color: white;
  width: 70vh;
  height: 70vh;
  margin: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}

.PhotoCelebration03 {
  background-color: white;
  width: 100vh;
  height: 70vh;
  margin: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}

.Step1 {
      transition: 0.5s;
    background-image: url('https://volsvik.com/photography/Club/Celebration/Celebration-0002-1.jpg');
}

.Step2 {
      transition: 0.5s;
    background-image: url('https://volsvik.com/photography/Club/Celebration/Celebration-0002-2.jpg');
}

.Step3 {
      transition: 0.5s;
    background-image: url('https://volsvik.com/photography/Club/Celebration/Celebration-0002-3.jpg');
}

.Step4 {
      transition: 0.5s;
    background-image: url('https://volsvik.com/photography/Club/Celebration/Celebration-0002-4.jpg');
}

.Step5 {
      transition: 2s;
    background-image: url('https://volsvik.com/photography/Club/Celebration/Celebration-0002-5.jpg');
}

.Step6 {
      transition: 2s;
    background-image: url('https://volsvik.com/photography/Club/Celebration/Celebration-0002-6.jpg');
}

.Step7 {
      transition: 0.5s;
    background-image: url('https://volsvik.com/photography/Club/Celebration/Celebration-0003-1.jpg');
}

.Step8 {
      transition: 0.5s;
    background-image: url('https://volsvik.com/photography/Club/Celebration/Celebration-0003-2.jpg');
}

.Step9 {
      transition: 2s;
    background-image: url('https://volsvik.com/photography/Club/Celebration/Celebration-0003-3.jpg');
}

.Step10 {
      transition: 0.5s;
    background-image: url('https://volsvik.com/photography/Club/Celebration/Celebration-0004-1.jpg');
}

.Step11 {
      transition: 0.5s;
    background-image: url('https://volsvik.com/photography/Club/Celebration/Celebration-0004-2.jpg');
}

.Step12 {
      transition: 2s;
    background-image: url('https://volsvik.com/photography/Club/Celebration/Celebration-0004-3.jpg');
}

.Step13 {
      transition: 0.5s;
    background-image: url('https://volsvik.com/photography/Club/Celebration/Celebration-0005-1.jpg');
}

.Step14 {
      transition: 0.5s;
    background-image: url('https://volsvik.com/photography/Club/Celebration/Celebration-0005-2.jpg');
}

.Step15 {
      transition: 2s;
    background-image: url('https://volsvik.com/photography/Club/Celebration/Celebration-0005-3.jpg');
}

.Step16 {
      transition: 0.5s;
    background-image: url('https://volsvik.com/photography/Club/Celebration/Celebration-0006-1.jpg');
}

.Step17 {
      transition: 0.5s;
    background-image: url('https://volsvik.com/photography/Club/Celebration/Celebration-0006-2.jpg');
}

.Step18 {
      transition: 2s;
    background-image: url('https://volsvik.com/photography/Club/Celebration/Celebration-0006-3.jpg');
}

.Step19 {
      transition: 0.5s;
    background-image: url('https://volsvik.com/photography/Club/Celebration/Celebration-0007-1.jpg');
}

.Step20 {
      transition: 0.5s;
    background-image: url('https://volsvik.com/photography/Club/Celebration/Celebration-0007-2.jpg');
}

.Step21 {
      transition: 2s;
    background-image: url('https://volsvik.com/photography/Club/Celebration/Celebration-0007-3.jpg');
}

.Description {
  width: 90vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: black;
}

 h2 a {
  display: inline-flex;
    /* color: black; */
    font-size: calc((3vmax + 3vmin) / 1.9);
}
/*=========================400=======================*/

@media (min-width: 400px) {

/* .PhotoCelebration {
  width: 70vw;
  height: 64vh;
} */

}

/*=========================1055=======================*/

@media (min-width: 1055px) {

/* .PhotoCelebration {
  width: 90vw;
  height: 40vw;
} */

.Description {
  width: 90vw;
  min-width: 500px;
}

}