.Photo-header {
  background-image: url('https://volsvik.com/photography/Series.jpg');
}

.Photo-header .card {
  width: 94vw;
  top: 64vh;
  left: 5px;
  position: relative;
}

.Photo-header span {
  font-weight: 700;
  width: 24vw;
  color: #fd6406;
}

.Photo-header span:hover {
  color: #000000;
}

/*=========================800=======================*/

@media (min-width: 800px) {
  .Photo-header span {
    font-weight: 400;
    width: 24vw;
    color: #535353;
  }

  .Photo-header span:hover {
    color: #fd6406;
  }
}
